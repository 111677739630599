.NoVotesEmojiWrapper {
  display: flex;
  align-items: center;

  .NoVotesEmoji {
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      width: 100%;
      height: 4px;
      background: grey;
    }

    &-large {
      width: 100px;
      height: 100px;
    }

    &-small {
      width: 47px;
      height: 47px;
    }
  }

  .noVotesPhrase {
    margin-left: 10px;

    &-large {
      font-size: 18px;
    }

    &-small {
      font-size: 14px;
    }
  }
}



