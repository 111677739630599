.page-demand {
    .page-sidebar {
        flex: 1;
    }

    .MuiSlider-root {
        * {
            z-index: 9999;
        }
    }
}
